import { memoize } from "@/lib/fp-ts";
import { singleton } from "@/lib/memo";
import { PassionCodec, type Passion } from "@seekdharma/common-types";
import {
  C,
  constant,
  fetch,
  parseJsonResponse,
  pipe,
  TE,
} from "@seekdharma/std";

export interface PassionApi {
  getPassions: () => TE.TaskEither<unknown, Passion[]>;
}

export const PassionApi = singleton((): PassionApi => {
  return {
    getPassions: pipe(
      fetch("/studio/anonymous/passions"),
      TE.chainW(parseJsonResponse(C.array(PassionCodec))),
      TE.map((passions) => {
        return passions.sort((a, b) => a.name.localeCompare(b.name));
      }),
      memoize(),
      constant,
    ),
  };
});
